@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 18px;
  min-width: 100%;
  overflow: hidden;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  position: relative;
  padding-bottom: 90px;
}

#contents_wrap:before {
  content: url(../images/common/yellow_shape.png);
  position: absolute;
  bottom: -1%;
  left: 0;
  z-index: -1;
}

#contents_wrap:after {
  content: url(../images/common/red_shape_s.png);
  position: absolute;
  bottom: -1%;
  right: 0;
  z-index: -1;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

.spnone {
  display: block;
}

@media only screen and (max-width: 834px) {
  .spnone {
    display: none;
  }
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_left {
  width: 24%;
  padding-left: 30px;
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: 100% !important;
    padding-left: 0;
    padding: 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_left {
    width: 18%;
  }
}

.hed_right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    padding: 10px;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.hed_right .telwrap {
  margin-right: 15px;
}

.hed_right .telwrap .tel2 {
  font-size: 38px;
}

@media only screen and (max-width: 834px) {
  .hed_right .telwrap .tel2 {
    font-size: 24px;
  }
  .hed_right .telwrap .tel2 span {
    font-size: 16px;
  }
}

.hed_right .telwrap .tel2 a {
  color: #333333;
}

.hed_right .telwrap .tel2 span {
  font-size: 24px;
}

.hed_right .telwrap .time {
  color: #ffffff;
  font-size: 13px;
  background: #b3c406;
}

.hed_right .hed_cont a {
  color: #ffffff;
  font-size: 24px;
  background: #e60012;
  display: block;
  width: 260px;
  margin: 0 auto;
  height: 70px;
  padding: 5px 0;
}

.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 834px) {
  .hide {
    transform: none;
  }
}

#header {
  width: 100%;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #header .hedwrap {
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .hedwrap {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .hedwrap {
    align-items: center;
  }
}

#header #siteID {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

#header #siteID a {
  opacity: 1;
}

#nav_global {
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    padding: 155px 35px;
  }
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

#nav_global ul li {
  position: relative;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: left;
  }
}

#nav_global ul li a {
  color: #333333;
  display: block;
  padding: 15px;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #nav_global ul li a {
    padding: 5px;
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global ul li a {
    border-bottom: 1px solid #b3c406;
    padding: 10px;
  }
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

#nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  top: 100%;
  left: -50%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#nav_global ul ul li a {
  color: #333;
}

.mainArea {
  width: 100%;
  max-width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .mainArea img {
    width: 50% !important;
    min-height: 45vw !important;
    margin: 0px -9% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainArea img {
    width: 60% !important;
    min-height: 32vw !important;
    margin: 0 -16% !important;
    object-fit: cover;
  }
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1495px;
  min-width: 240px;
  height: 105px;
  font-size: 2.7em;
  color: #ffffff;
  line-height: 1.3em;
  text-align: center;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    font-size: 30px;
    width: 100%;
    padding: 28px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    font-size: 1.8em;
    width: 100%;
    padding: 30px;
    height: 240px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .mainwrap .main_txt {
    width: 100%;
    font-size: 36px;
  }
}

#local-keyvisual-title {
  background: url(../images/contents/local.jpg) center no-repeat;
  background-size: cover;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: center;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 150px 0;
  background: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 100px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.drawer-hamburger {
  z-index: 101 !important;
}

.drawer-hamburger:after {
  content: "menu";
  position: absolute;
  bottom: 2px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.drawer-hamburger span {
  background: #333 !important;
}

.drawer-hamburger span:before, .drawer-hamburger span:after {
  background: #333 !important;
}

.drawer-hamburger.change-color span {
  background: #2e4121 !important;
}

.drawer-hamburger.change-color span:before, .drawer-hamburger.change-color span:after {
  background: #2e4121 !important;
}

.drawer-nav {
  z-index: 101 !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 20px;
}

.drawer-hamburger {
  z-index: 201 !important;
}

.drawer-overlay {
  z-index: 201 !important;
}

.drawer-nav {
  z-index: 202 !important;
}

.drawer-open .drawer-hamburger .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .for-pc {
    display: none !important;
  }
}

.not-for-pc {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .not-for-pc {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .not-for-sp {
    display: none !important;
  }
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  background-size: cover;
}

footer#global_footer .inner {
  padding: 30px 0 60px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  footer#global_footer #nav_footer {
    display: flex;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  footer#global_footer #nav_footer ul {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  footer#global_footer #nav_footer li {
    text-align: left;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 2px 10px;
  display: block;
  color: #333333;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.footer2 {
  padding: 87px 0;
  background: url(../images/common/cont_bg.jpg) center no-repeat;
}

@media only screen and (max-width: 834px) {
  .footer2 {
    background-size: cover;
  }
}

.footer2 .ft_cont_wrap {
  background: rgba(255, 255, 255, 0.8);
  padding: 80px;
}

.footer2 .fth2 h2 {
  font-size: 1.8em;
  position: relative;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.footer2 .fth2 h2:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 60px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #e60012;
  border-radius: 2px;
}

@media only screen and (max-width: 640px) {
  .footer2 .fth2 h2 {
    font-size: 26px;
  }
}

.footer2 .ftwrap {
  display: flex;
  justify-content: center;
  margin-top: 37px;
  align-items: flex-end;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap {
    display: block;
  }
}

.footer2 .ftwrap .fttelwrap {
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .fttelwrap {
    margin-right: 0;
  }
}

.footer2 .ftwrap .fttelwrap .time {
  color: #ffffff;
  font-size: 13px;
  background: #b3c406;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .fttelwrap .time {
    margin: 0 auto;
    width: 250px;
  }
}

.footer2 .ftwrap .tel2 {
  font-size: 38px;
}

.footer2 .ftwrap .tel2 span {
  font-size: 24px;
}

.footer2 .ftwrap .tel2 a {
  color: #333333;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .tel2 {
    padding: 0;
    margin-right: 15px;
    font-size: 1.5em;
  }
}

.footer2 .ftwrap .tel2 a {
  color: #333333;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .tel2 a {
    padding: 0;
  }
}

.footer2 .ftwrap .ftcont a {
  display: block;
  font-size: 1.25em;
  color: #ffffff;
  background: #e60012;
  padding: 5px 0;
  display: block;
  width: 260px;
  margin: 0 auto;
  height: 70px;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .ftcont a {
    margin: 15px auto;
  }
}

.ftcontwrap {
  background: rgba(255, 255, 255, 0.8);
  padding: 50px;
  max-width: 800px;
  margin: 0 auto;
}

.small_bg {
  background: #e60012;
}

#copy {
  color: #fff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 11%;
  }
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
    text-align: center;
  }
}

.ft_L {
  text-align: left;
  width: 40%;
  color: #333333;
  line-height: 1.3em;
}

.ft_L .address {
  margin-top: 10px;
}

.ft_L .tel2 a {
  color: #333333;
}

.ft_L .ftlogo {
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_L {
    padding-left: 10px;
  }
}

.ft_L ul li:nth-of-type(1) {
  margin-bottom: 30px;
}

.ft_nav {
  width: 56%;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_nav {
    width: 36%;
  }
}

/* box */
.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.h2_01 h2 {
  font-size: 40px;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1em;
  border-bottom: 1px solid #e60012;
  padding-bottom: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.7em;
  }
}

.h2_01 h2 span {
  font-size: 30px;
}

.h2_02 h2 {
  font-size: 1.9em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: url(../images/common/midashi_bg.png) center no-repeat;
  padding: 30px;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.7em;
  }
}

.h2_02 h2 span {
  font-size: 18px;
}

.h2_03 h2 {
  font-size: 1.9em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #ffffff;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.7em;
  }
}

.h2_04 h2 {
  font-size: 1.6em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: #e60012;
  color: #ffffff;
  padding: 10px;
  border-bottom: 5px solid #fed700;
  border-left: 5px solid #b3c406;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 1.7em;
  }
}

.txt {
  line-height: 2em;
}

.list {
  max-width: 550px;
  margin: 0 auto;
}

.list li {
  margin: 15px;
  font-size: 18px;
  float: none !important;
  position: relative;
  padding-left: 30px;
}

.list li:before {
  content: url(../images/common/light_green_icon.png);
  position: absolute;
  left: 0;
  top: 0;
}

.list li a {
  color: #333333;
  background: #f3f3f3;
  padding: 10px;
  display: block;
}

.btn a {
  color: #ffffff;
  font-size: 24px;
  padding: 15px;
  display: block;
  width: 260px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  background: #e60012;
}

.btn a img {
  margin-right: 15px;
}

.btn a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: '';
  background: #fed700;
  transform-origin: right top;
  transform: scale(0, 1);
  transition: transform .3s;
}

.btn a:hover::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.sec_01 {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    display: block;
    padding: 0 10px;
  }
  .sec_01 .flxL {
    width: 100% !important;
    justify-content: center !important;
    padding-top: 100px;
  }
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
  .sec_01 .flxR .txt br:nth-of-type(3) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(5) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(6) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(8) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(10) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(11) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(12) {
    display: none;
  }
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .sec_01 {
    align-items: center;
  }
  .sec_01 .flxR .txt br:nth-of-type(3) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(5) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(6) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(8) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(10) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(11) {
    display: none;
  }
  .sec_01 .flxR .txt br:nth-of-type(12) {
    display: none;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .sec_01 {
    display: block;
    padding: 0 10px;
  }
  .sec_01 .flxL {
    width: 100% !important;
    padding-top: 100px;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
}

.sec_01 .flxL {
  width: 56%;
  position: relative;
}

.sec_01 .flxL .table_img td {
  border: none !important;
  padding: 0 !important;
}

.sec_01 .flxL .table_img td img {
  width: 100% !important;
}

.sec_01 .flxL .table_img tr:nth-of-type(2) td {
  padding-top: 10px !important;
}

.sec_01 .flxR {
  width: 40%;
}

.red_shape {
  background: url(../images/common/red_shape.png) left top/0% no-repeat;
  z-index: -1;
  transition: all 5s;
}

.green_shape {
  background: url(../images/common/green_shape.png) right top/0% no-repeat;
  z-index: -1;
  transition: all 5s;
}

.btnbox {
  justify-content: center;
}

.btnbox .box {
  width: 46%;
  margin: 15px;
}

@media only screen and (max-width: 640px) {
  .btnbox .box {
    width: 100% !important;
  }
}

.btnbox2 {
  justify-content: center;
}

.btnbox2 .box {
  width: 30%;
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .btnbox2 .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox2 .box {
    width: 48%;
  }
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  background: url(../images/common/cont2_bg.jpg);
  padding-bottom: 50px;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
    padding: 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .sec_02 {
    display: block;
    align-items: center;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
    padding: 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .sec_02 {
    align-items: center;
  }
}

.sec_02 .flxL {
  width: 48%;
}

.sec_02 .flxR {
  width: 48%;
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: flex-start;
  }
}

.instagram div.sns_list > div {
  width: 20% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 48% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 33% !important;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 240px !important;
  height: 240px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 100% !important;
    width: 100% !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  position: relative;
  width: 18%;
  margin: 5px;
}

.bnrbox .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    height: 200px;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box {
    width: 48%;
    margin: 5px;
  }
}

.bnrbox .box span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 60px;
  height: 25px;
  color: #b3c406;
  font-size: 1.12em;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box span {
    font-size: 1.3em;
  }
}

.bnrbox .box h3 {
  display: none;
}

.bnrbox2 {
  justify-content: center;
}

.bnrbox2 .box {
  position: relative;
  width: 20%;
  margin: 5px;
}

.bnrbox2 .box a {
  display: block;
}

.bnrbox2 .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box {
    width: 100%;
    height: 200px;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox2 .box {
    width: 48%;
    margin: 5px;
  }
}

.bnrbox2 .box span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 60px;
  height: 25px;
  color: #b3c406;
  font-size: 1.12em;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox2 .box span {
    font-size: 1.3em;
    width: 270px;
  }
}

.bnrbox2 .box h3 {
  display: none;
}

.slider2:before {
  content: "";
  width: 100%;
  height: 36%;
  background-size: 5em;
  display: block;
  position: absolute;
  right: 0;
  z-index: 3;
}

.slider2 li {
  margin: 0 15px;
}

.slider2 li img {
  width: 100% !important;
}

.newswrap2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .newswrap2 {
    display: block;
  }
  .newswrap2 .flxL {
    width: 100% !important;
    padding: 50px 0 !important;
  }
  .newswrap2 .flxR {
    width: 100% !important;
  }
}

.newswrap2 .flxL {
  width: 28%;
  background: #e60012;
  padding: 135px 0;
}

.newswrap2 .flxR {
  width: 63%;
}

@media only screen and (max-width: 834px) {
  .newswrap {
    margin: 0 10px;
  }
}

.newswrap .news {
  padding-left: 30px;
  height: 300px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    margin-top: 30px;
    padding-left: 0;
  }
}

.newswrap .news dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
}

.newswrap .news dd {
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
}

.newswrap .news dl {
  display: block !important;
  border-bottom: 1px dashed #333 !important;
  padding: 10px;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.txt {
  line-height: 2em;
}

.h3_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 5px 10px;
  border-bottom: 1px dashed #fed700;
  border-left: 5px solid #b3c406;
}

.h3_02 h3 {
  font-size: 1.25em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-left: 40px;
  border-bottom: 1px solid #fed700;
  padding-bottom: 5px;
}

.h3_02 h3:before {
  content: "";
  width: 30px;
  height: 30px;
  background: #b3c406;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.h3_03 h3 {
  font-size: 1.25em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-left: 40px;
  border-bottom: 1px solid #b3c406;
  padding-bottom: 5px;
}

.h3_03 h3:before {
  content: "";
  width: 30px;
  height: 30px;
  background: #fed700;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.h3_03 h3 span {
  padding-right: 10px;
}

.h3_04 h3 {
  font-size: 1.25em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-left: 40px;
  border-bottom: 1px solid #fed700;
  padding-bottom: 5px;
}

.h3_04 h3:before {
  content: "";
  width: 30px;
  height: 30px;
  background: #e60012;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.h3_04 h3 span {
  padding-right: 10px;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .img {
    width: 100% !important;
  }
  .sec_03 .txt {
    width: 100% !important;
  }
}

.sec_03 .img {
  width: 48%;
}

.sec_03 .txt {
  width: 48%;
}

.sec_03_rvs {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_03_rvs {
    display: block;
  }
  .sec_03_rvs .img {
    width: 100% !important;
  }
  .sec_03_rvs .txt {
    width: 100% !important;
  }
}

.sec_03_rvs .img {
  width: 48%;
}

.sec_03_rvs .txt {
  width: 48%;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .txt {
    width: 100% !important;
  }
  .sec_04 .table_img2 {
    width: 100% !important;
  }
}

.sec_04 .table_img2 {
  width: 18%;
}

.sec_04 .txt {
  width: 80%;
}

.sec_05 {
  background: #f7f7f7;
  padding: 40px;
}

.table_cont {
  background: #f7f7f7;
  padding: 10px;
}

.table_cont td {
  border: none !important;
  padding: 0 !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .table_cont td {
    display: block !important;
  }
}

.table_cont td span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 1px solid #b3c406;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .table_cont .txt {
    width: 100% !important;
  }
  .table_cont .img {
    width: 100% !important;
    text-align: center !important;
    margin-top: 15px;
  }
  .table_cont .img img {
    width: 100% !important;
  }
}

.ippan_wrap {
  background: #F7F7F7;
  padding: 40px;
}

.qa dl {
  display: block !important;
  padding: 10px !important;
  background: #F7F7F7;
  margin: 10px 0;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #b3c406;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.qa dt:after {
  font-family: "Font Awesome 5 pro";
  content: "\f13a";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 40px;
  /*サイズ*/
  right: 0;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #e60012;
  /*アイコン色*/
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #fed700;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
}

.table_price {
  border-top: 1px solid #ccc;
}

.table_price dt {
  border: none !important;
}

@media only screen and (max-width: 834px) {
  .table_price dt {
    width: 40% !important;
  }
}

.table_price dd {
  border: none !important;
}

@media only screen and (max-width: 834px) {
  .table_price dd {
    width: 60% !important;
  }
}

.table_price dl {
  border-bottom: 1px solid #ccc;
}

.table_img2 td {
  border: none !important;
  padding: 0 !important;
}

.table_img2 td img {
  width: 100% !important;
}

.table_img2 tr:nth-of-type(2) td {
  padding-top: 10px !important;
}

.top_txt {
  background: #b3c406;
  padding: 30px;
  max-width: 550px;
  margin: 0 auto;
}

.txt_address span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 1.25em;
}

.table_step td {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .table_step .icon {
    display: none;
  }
}

.table_step .txt span {
  text-indent: -1em;
}

@media only screen and (max-width: 834px) {
  .table_step .arrow {
    width: 15% !important;
    vertical-align: top !important;
  }
}

.cont_form a {
  color: #333333;
}

.cont_form a:hover {
  text-decoration: underline;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.imgbox {
  justify-content: center;
}

.imgbox .box {
  width: 30%;
  text-align: center;
  margin: 10px;
}

.imgbox .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.imgbox .box h3 {
  text-align: center;
  padding: 10px;
}

.imgbox .box article > div {
  display: none;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.txt_contact .tel2 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.txt_contact .tel2 a {
  color: #333333;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 290px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 530px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 530px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #dedfda;
  background-size: cover;
  z-index: 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.bg_100per_wrap2 {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #e60012;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #e60012;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}
